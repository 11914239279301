.class-card
  position: relative
  background: radial-gradient(#36439c, #2b2446 85%)
  border-radius: 5px
  border: 1px solid black
  width: 100%
  height: 21em
  padding: 1.1em
  box-sizing: border-box

  @media (min-width: 1400px)
    // * The minimum with is dictated by the gaps.
    width: 33em

  &.promoted
    $shadow: 0px 0px 1.9px 1.4px rgba(243, 225, 129, 0.477)
    -webkit-box-shadow: $shadow
    box-shadow: $shadow

  .crown-icon
    position: absolute
    top: 0.3em
    left: 0.2em
    color: goldenrod

  .name
    font-family: 'Norse'
    font-weight: bold
    font-size: 1.6em
    color: white
    -webkit-text-fill-color: white
    -webkit-text-stroke: 1px black

  .image
    border-radius: 4px
    border: 1px solid black
    overflow: hidden

  .greyed-out
    filter: grayscale(100%)
    
    &.dark
      -webkit-filter: grayscale(100%) brightness(62%)
      filter: grayscale(100%) brightness(62%)

  .heading
    font-family: 'Norse'
    font-weight: bold
    font-size: 1.1em
    color: #f0ffff
    -webkit-text-fill-color: #f0ffff
    -webkit-text-stroke: 0.2px #fffaa3
    margin-bottom: -0.1em

  .content-text
    font-size: 0.9em

  .button
    cursor: pointer
