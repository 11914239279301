@use 'sass:color'

$size: 3.2rem

.treasure-level-selector
  width: $size
  height: $size

  .icon-container
    position: relative
    width: $size
    height: $size

  .icon
    position: absolute
    top: 0
    left: 0
    width: $size
    height: $size
    z-index: -2
    -webkit-user-select: none
    user-select: none

    &.max-level
      $shadow: 0px 0px 1.9px 1.4px rgba(243, 225, 129, 0.477)
      -webkit-box-shadow: $shadow
      box-shadow: $shadow

  .lock-icon
    position: absolute
    bottom: -0.2rem
    left: -0.2rem
    color: goldenrod

  .crown-icon
    position: absolute
    top: -0.3rem
    right: -0.2rem
    color: goldenrod

  .level
    position: absolute
    top: 0
    left: 0
    width: $size
    height: $size
    line-height: $size
    font-family: 'Norse'
    font-weight: bold
    font-size: 3em
    -webkit-text-fill-color: white
    -webkit-text-stroke: 2px black
    -webkit-user-select: none
    user-select: none
    text-align: center
    padding-top: 0.1em
    z-index: -1

  .controls
    opacity: 0
    position: absolute
    top: 0
    left: 0
    width: $size
    height: $size
    transition: 300ms ease-in-out
    &:hover
      opacity: 0.7

  .button
    position: relative
    cursor: pointer

    &.up
      width: $size
      height: calc($size * 0.5)
      background: color.change(gold, $alpha: 0.15)
      border-top-left-radius: 4px
      border-top-right-radius: 4px
      
      & > svg
        position: absolute
        top: calc($size * -0.25)
        left: 0
        width: $size
        text-align: center

    &.down
      width: $size
      height: calc($size * 0.5)
      background: color.change(red, $alpha: 0.15)
      border-bottom-left-radius: 4px
      border-bottom-right-radius: 4px
      
      & > svg
        position: absolute
        bottom: calc($size * -0.25)
        left: 0
        width: $size
        text-align: center

    &.locked
      cursor: inherit