@use 'sass:color'

.build-info
  .name-input
    font-size: 2em

  .save-button
    color: color.adjust(white, $lightness: -20%)
    font-size: 2em

    &:hover
      color: white
