@mixin enhanceFont
  font-family: 'Norse'
  font-weight: bold
  font-size: 1.1em
  white-space: nowrap
  
.enhance-numbers
  @include enhanceFont
  font-size: 1em
  color: #00f5ff

.enhance-status
  @include enhanceFont
  font-size: 1.13em
  color: #f0ffff

.enhance-class-domain
  @include enhanceFont
  font-size: 1.13em
  color: #80a1ff

.enhance-ability-domain
  @include enhanceFont
  color: #ff8af4

.enhance-treasure-domain
  @include enhanceFont
  color: #eaa17e

.enhance-status-effect
  @include enhanceFont
  color: #00d1fb

.enhance-obtain-boss-1
  @include enhanceFont
  font-size: 1.3em
  color: ivory

.enhance-obtain-boss-2
  @include enhanceFont
  font-size: 1.3em
  color: lightyellow

.enhance-obtain-boss-3
  @include enhanceFont
  font-size: 1.3em
  color: yellow

.enhance-obtain-boss-4
  @include enhanceFont
  font-size: 1.3em
  color: yellow

.enhance-obtain-boss-5
  @include enhanceFont
  font-size: 1.3em
  color: gold

.enhance-obtain-boss-6
  @include enhanceFont
  font-size: 1.3em
  color: orange

.enhance-obtain-boss-7
  @include enhanceFont
  font-size: 1.3em
  color: orangered

.enhance-obtain-boss-8
  @include enhanceFont
  font-size: 1.3em
  color: red

.enhance-obtain-chests
  @include enhanceFont
  font-size: 1.2em
  color: lightsteelblue

.enhance-obtain-quest-1
  @include enhanceFont
  font-size: 1.2em
  color: mediumorchid

.enhance-obtain-quest-2
  @include enhanceFont
  font-size: 1.2em
  color: mediumslateblue

.enhance-obtain-quest-3
  @include enhanceFont
  font-size: 1.2em
  color: darkviolet