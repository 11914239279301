@use 'sass:color'

$label-color: white
  
.name-input
  .text
    position: relative
    padding-right: 0.3em

  input
    text-align: right
    background: none
    border: none

    &:focus
      outline: none

  button
    color: color.adjust($label-color, $lightness: -20%)
    background: none
    border: none
    cursor: pointer
    padding: 0

    &.edit
      position: absolute
      top: -0.1em
      right: -0.6em
      font-size: 0.6em
      vertical-align: super

    &:hover
      color: $label-color
      &.submit
        color: palegreen
      &.cancel
        color: salmon

  .placeholder-display
    color: color.adjust($label-color, $lightness: -40%)
