@use 'sass:color'

.party-roster
  .info
    backdrop-filter: blur(20px)
    z-index: 1

  .heading
    font-family: 'Norse'
    font-weight: bold
    font-size: 1.4em
    -webkit-text-fill-color: white
    -webkit-text-stroke: 1px black
    margin-bottom: -0.2em

    &.sub
      font-size: 0.95em
      -webkit-text-stroke: 0.6px black

  .mastered-class
    font-family: 'Norse'
    font-weight: bold
    padding: 0.14em 0.8em 0 0.8em
    text-align: center
    border-radius: 8px
    border: 1.5px solid #3d3732
    outline: 0.9px solid black
    background: linear-gradient(#262521 45%, #181310)
    color: #00bdd4

  .class-domain
    color: #80a1ff
    font-size: 0.8em
    margin-bottom: 0.2em

  .ability-domain
    color: #ff8af4
    font-size: 0.8em
    margin-bottom: 0.2em

  .card-container
    position: relative
    display: inline-block

    @media (min-width: 1400px)
      width: min-content

    @mixin bg-colors($color)
      background: linear-gradient($color, color.adjust($color, $lightness: -10%))
      border: 1px solid black
      &:hover
        background: linear-gradient(color.adjust($color, $lightness: -15%), color.adjust($color, $lightness: -20%))
        border-color: 1px solid black

    button
      position: absolute
      right: -0.73rem
      z-index: 0
      --bs-btn-padding-y: 0.03rem

      &.remove
        @include bg-colors(crimson)
        top: 1.4rem
        --bs-btn-padding-x: 0.2rem

      &.promote
        @include bg-colors(goldenrod)
        top: 3.6rem
        --bs-btn-padding-x: 0.14rem