.treasure-card
  background: radial-gradient(#36439c, #2b2446 85%)
  border-radius: 5px
  border: 1px solid black
  // * The minimum with is dictated by the gaps.
  width: 33em
  padding: 1.1em
  box-sizing: border-box

  .name
    font-family: 'Norse'
    font-weight: bold
    font-size: 1.8em
    color: white
    -webkit-text-fill-color: white
    -webkit-text-stroke: 1px black

  .image
    border-radius: 4px
    border: 1px solid black
    overflow: hidden
  
  .heading
    font-family: 'Norse'
    font-weight: bold
    font-size: 1em
    color: #f0ffff
    -webkit-text-fill-color: #f0ffff
    -webkit-text-stroke: 0.2px #fffaa3

  .title-text
    font-family: 'Norse'
    font-weight: bold
    font-size: 1.2em

  .context-text
    font-size: 0.9em