$bar-width: 1rem
  
.treasure-tree
  .container
    overflow-x: auto
    min-width: none
    max-width: 95vw

    @media (min-width: 1400px)
      min-width: 40vw
      max-width: 53vw

  .info
    backdrop-filter: blur(20px)
    z-index: 1

  .levels
    position: relative

  .numbers
    font-family: 'Norse'
    font-weight: bold
    font-size: 1.8rem
    -webkit-text-fill-color: white
    -webkit-text-stroke: 0.7px black
    -webkit-user-select: none
    user-select: none

    &.glow
      text-shadow: silver 0px 0px 4px

  .domain-heading
    font-family: 'Norse'
    font-weight: bold
    font-size: 1em
    -webkit-text-fill-color: white
    -webkit-text-stroke: 0.4px black
    margin-bottom: -0.2em

  .treasure-domain
    color: #eaa17e
    font-size: 0.85em

  .ability-domain
    color: #ff8af4
    font-size: 0.85em

  @mixin background($color)
    background: linear-gradient(transparent, $color 20% 80%, transparent)
    
  .bar
    border-width: 1px
    border-style: solid
    border-image: linear-gradient(transparent, black, transparent) 1 100%
    z-index: -1

    &.glow
      border-image: linear-gradient(transparent, silver, transparent) 1 100%

  .bar0
    @include background(dodgerblue)
  
  .bar1
    @include background(cyan)

  .bar2
    @include background(lime)

  .bar3
    @include background(yellowgreen)

  .bar4
    @include background(yellow)

  .bar5
    @include background(gold)

  .bar6
    @include background(orange)

  .bar7
    @include background(orangered)

  .bar8
    @include background(red)

  .bar9
    @include background(purple)

  .ragnarok
    font-family: 'Norse'
    font-weight: bold
    font-size: 1.6em
    background: linear-gradient(red, darkred)
    -webkit-text-stroke: 0.8px black
    -webkit-text-fill-color: transparent
    -webkit-background-clip: text
    background-clip: text
    vertical-align: -0.1em

  .headings
    -webkit-user-select: none
    user-select: none
