@font-face
  font-family: 'Dalek Pinpoint'
  src: local('Dalek Pinpoint')
  src: url(./assets/fonts/DalekPinpointBold.ttf) format('truetype')

@font-face
  font-family: 'Norse'
  src: local('Norse')
  src: url(./assets/fonts/Norse.otf) format('opentype')

@font-face
  font-family: 'Norse'
  src: local('Norse')
  src: url(./assets/fonts/NorseBold.otf) format('opentype')
  font-weight: bold

body
  background: linear-gradient(#3f217b, #1a1c1f 80%)
  background-attachment: fixed
  height: 100vh
  color: white !important

h1, h2, th
  font-family: 'Norse'
  font-weight: bold

h1
  -webkit-text-fill-color: white
  -webkit-text-stroke: 1.2px black
  font-size: 3.2rem
  margin-bottom: -0.1rem

h2
  -webkit-text-fill-color: white
  -webkit-text-stroke: 0.7px black
  font-size: 2rem

.danger
  color: red
  -webkit-text-fill-color: red
  
.nowrap
  white-space: nowrap

.tooltip
  --bs-tooltip-bg: #111214
  --bs-tooltip-color: white
