.json-text-area

  .text-area
    position: relative

    textarea
      width: 100%

    .buttons
      position: absolute
      top: 0.2em
      right: 0.7em

      .button
        cursor: pointer